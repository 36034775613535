<template>
  <div class="test_case">
    <div class="classes">
      <h3 class="title">在校班级</h3>
      <el-select v-model="value" placeholder="请选择" clearable>
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <!-- 每日一考成绩 -->
    <div class="tc">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        border
        :tree-props="{
          children: 'children',
          hasChildren: 'hasChildren',
        }"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="class_Name"
          label="班级名称"
          sortable
          width="180"
        >
        </el-table-column>
        <el-table-column prop="user_Name" label="带班讲师"> </el-table-column>
        <el-table-column prop="course_Name" label="课程阶段/阶段详情">
        </el-table-column>
        <el-table-column prop="status" label="详情">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">
              <i class="el-icon-view watch"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "TestCase",
  data() {
    return {
      options: [],
      value: "",
      tableData: [],
      sourceData: [],
    };
  },
  methods: {
    tableRowClassName({ row }) {
      if (row.status === "未进行") {
        return "warning-row";
      } else {
        return "";
      }
    },
    handleClick(row) {
      this.$router.push({
        name: "ClassCase",
        params: {
          class_Id: row.class_Id,
          course_Id: row.course_Id,
          class_Name: row.class_Name,
        },
        query: row,
      });
    },
  },
  computed: {
    ...mapState(["loading"])
  },
  async created() {
    let res = await this.$datas.jindu;
    if (res.status == 200) {
      let tabledata = [];
      let class_rooms = [];

      for (let key in res.data.msg.data) {
        tabledata.push({
          ...res.data.msg.data[key][0],
          id: key,
          children: res.data.msg.data[key].map((item) => ({
            ...item,
            id: `${key}-${item.course_Id}`,
          })),
        });
        class_rooms.push({ value: key, label: key });
      }
      tabledata.sort((item1, item2)=>{
         let name1 = item1.class_Name.replace(/[A-Z]+/, '');
         let name2 = item2.class_Name.replace(/[A-Z]+/, '');
         return name2-name1;
      });
      this.tableData = tabledata;
      this.sourceData = tabledata; //存储原始数据
      this.options = class_rooms;
    }
  },
  watch: {
    value(newV) {
      if(newV){
        let table_data = [];
        for (let i in this.sourceData) {
            if (this.sourceData[i].class_Name == newV) {
            table_data.push({
                ...this.sourceData[i],
                id: `${this.sourceData[i].class_Id}-${this.sourceData[i].course_Id}`,
            });
            break;
            }
        }
        this.tableData = table_data;
      }else{
        this.tableData = this.sourceData;
      }
    },
  },
};
</script>
<style lang="less" scope>
.classes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
  .title {
    margin-right: 15px;
    color: #606266;
    font-weight: 400;
    font-size: 14px;
  }
}
.el-button--text {
  font-size: 18px;
}
.el-table .warning-row {
  background: oldlace;
}
</style>
